/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@apollo/client';
import { styled } from '@material-ui/core';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { useCallback, useEffect, useState } from 'react';

import {
  DownloadCabinButtons,
  DownloadProps,
} from '../components/partials/Download/Download';
import deviceDetection from '../utils/deviceDetection';
import { Breakpoints } from '../utils/enums';
import Layout from 'components/partials/Layout';
import { Button, Container, Typography } from 'components/UI';
import { useDynamicDownloadLink } from 'utils/useDynamicDownloadLink';

const Content = styled('div')(({ theme }) => ({
  '& > div': {
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },

  margin: `${theme.typography.pxToRem(100)} auto 0`,
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    maxWidth: theme.typography.pxToRem(400),
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(1),
}));

const Wrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Layout>
      <Container>
        <Content>{children}</Content>
      </Container>
    </Layout>
  );
};

const VERIFY_QUERY = gql`
  mutation VerifyCommunicationChannel($token: String!) {
    verifyCommunicationChannel(token: $token)
  }
`;

const VerifyEmail: React.FC<Props> = ({ data }) => {
  const { isIos, isAndroid } = deviceDetection();
  const [verifyUsersEmail, { loading, error }] = useMutation(VERIFY_QUERY);
  const location = useLocation();
  const [hasNoToken, setHasNoToken] = useState(false);
  const [itemVerified, setItemVerified] = useState<string>('account');

  const dynamicLinkValue = useDynamicDownloadLink();

  const handleVerifyWithToken = useCallback(async () => {
    const urlParams = new URLSearchParams(location.search);
    const tokenParam = urlParams.get('token');

    if (tokenParam) {
      const token = decodeURIComponent(tokenParam);

      /*
       * Parse the token WITHOUT VERIFICATION. This is safe because we only use
       * the result to display the verified channel to the user. No business
       * logic should use this data.
       */
      const { channel } = JSON.parse(JSON.parse(atob(token)).data);

      switch (channel) {
        case 'email':
          setItemVerified('email address');
          break;
        case 'phone':
          setItemVerified('phone number');
      }

      await verifyUsersEmail({ variables: { token } });
    } else {
      setHasNoToken(true);
    }
  }, [location.search, verifyUsersEmail]);

  useEffect(() => {
    handleVerifyWithToken();
  }, [handleVerifyWithToken]);

  if (loading) {
    return (
      <Wrapper>
        <h2>Loading...</h2>
      </Wrapper>
    );
  }

  if (error || hasNoToken) {
    return (
      <Wrapper>
        <Typography variant="h2">Oh no!</Typography>
        {error?.message && <Text variant="h4">{error?.message}</Text>}
        <Typography>
          Please reopen the app and go though the verification process again.
        </Typography>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Typography variant="h2">Welcome aboard!</Typography>
      <Text variant="h4">Your {itemVerified} is now verified!</Text>
      <Typography>
        What are you waiting for? It&apos;s time to book your first affordable
        flight.
      </Typography>

      {isIos || isAndroid ? (
        <div>
          <Button to="airfordable://bookings/new">Open Application</Button>
        </div>
      ) : (
        <DownloadCabinButtons
          content={data.downloadYaml.content}
          dynamicLink={dynamicLinkValue}
        />
      )}
    </Wrapper>
  );
};

interface Props {
  data: {
    downloadYaml: {
      metadata: MetaData;
      content: DownloadProps['content'];
    };
  };
}

export const query = graphql`
  query VerifyAccountQuery {
    downloadYaml {
      ...DownloadContent
    }
  }
`;

export default VerifyEmail;
